.home-container {
    text-align: center;
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    background-attachment: fixed;
}

.home-header {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.header-logo {
    max-width: 150px;
    height: auto;
}

.header-text {
    text-align: center;
}

.navbar {
    margin-bottom: 20px;
    background-color: #00796b; 
}

.navbar-brand, .nav-link {
    color: #ffffff !important;
}

.nav-link:hover {
    color: #ffbe76 !important; 
}

.services-section {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-section h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #004d40; 
}

.services-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.services-carousel {
    flex: 1;
    padding-right: 20px;
}

.services-info {
    flex: 1;
    padding-left: 20px;
    text-align: left;
}

.services-list {
    list-style: none;
    padding: 0;
}

.services-list li {
    display: inline-block;
    width: 45%;
    text-align: left;
    margin: 10px 2.5%;
    background-color: #b2dfdb; 
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #004d40;
}

.services-list li:hover {
    background-color: #80cbc4; 
}

.about-section, .contact-section {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-section h2, .contact-section h2, .contact-section h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #004d40; 
}

.carousel-item img {
    height: 500px; 
    object-fit: cover; 
    border-radius: 8px; 
}

.about-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.about-content p {
    flex: 1;
}

.about-image {
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.contact-details, .contact-hours {
    flex: 1;
    text-align: center;
}

.hours-list, .serving-areas-list {
    list-style: none;
    padding: 0;
}

.hours-list li, .serving-areas-list li {
    margin: 5px 0;
}

/* Media Queries */
@media (max-width: 768px) {
    .services-content {
        flex-direction: column;
    }
    
    .services-carousel {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .services-info {
        padding-left: 0;
    }

    .services-section h2 {
        font-size: 1.2em;
    }

    .services-list li {
        width: 90%; 
        margin: 10px auto; 
    }

    .about-content, .contact-content {
        flex-direction: column;
    }

    .about-image, .contact-image {
        width: 100%;
        height: auto;
    }
}
